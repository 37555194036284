import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import clee from '../../assets/images/eatl.png';
import shapeb from '../../assets/images/1-2.png';
const About = () => {
return (
<Common>
   <section className='sub-page-banner'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>About Us</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>
                  About Us
               </Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container>
      <Row>
         <Col md={5} data-aos="fade-right">
         {/* 
         <div className='shape-sup'>
            <img src={clee} alt="clee" />
         </div>
         */}
         <div className="rs-animation-shape">
            <div class="eatlbd-shape">  
               <img src={shapeb} alt="clee" />
            </div>
            <img className='eatlbg-im' src={clee} alt="clee" />
         </div>
         </Col>
         <Col md={7} data-aos="fade-left">
         <div className='about-company'>
            <h5>MORE ABOUT OUR COMPANY</h5>
            <p>
            Ethics Advance Technology Limited (EATL)  is a private company and a leading enterprise with innovative ICT services and solutions in Bangladesh established in November 1999. EATL has developed partnership and collaborations with different renowned local and international companies, private sector organizations, NGOs, donors, Government and also some of the world's renowned IT expertise organization such as IBM, Huawei, KDDI Japan, Sun Oracle (infrastructural partner), TeleTalk, Grameenphone, Robi, Six Dee Telecom Solutions and many others for developing technology and efficient solutions.
            </p>
            <p>
            EATL’s business strategy combines application support, system design, database administration, feasibility study, software development, hardware solution, training, social media consultancy and ICT services in development sectors as well. EATL launched the first ever mobile application store in Bangladesh in 2012 which was branded as ‘EATLApps’. EATL has launched country’s largest mobile application development contest known as ‘EATL-Prothom Alo Mobile Apps Development Contest’ for searching the brightest talents in Bangladesh.
            </p>
            <h3 className='mt-5'>
            EATL’s Expertise
            </h3>
            <ul className='list-style-one'>
                     <li>	One of the major CMMI Level 3 certified companies in Bangladesh.</li>
                     <li>	Practical experience in developing Micro Services-based System Architecture and Distributed Database Management System. </li>
                     <li>	Real-life deployment of large-scale databases that can handle millions of data smoothly for government level public services.</li>
                     <li>	Expertise in Developing Android Mobile Apps.</li>
                     <li>	Expertise in Developing Online Application using PHP and Laravel.</li>
                     <li>	Capacity to Develop System to Visualize Data and Link with Google Map.</li>
                     <li>	Specialized in Developing Info. Systems for Monitoring and Decision Making.</li>
                     <li>	Able to Handle Comprehensive Database.</li>
                     <li>	Able to Build and Maintain Proper Linux Servers.</li>
                     <li>	Experienced in Working with INGO and government projects.</li>
                     <li>	Experienced in handling large volume public data with proper security measures.</li>

            </ul>

           
         </div>
         </Col>
      
      </Row>


      

      <Row className='border-dashed mt-4 mb-5 pb-5'>
         <Col md={12}>
         <div className='about-company mt-4 left-0'>
           
            <h3>
            EATL Strategies & Focus Areas
            </h3>
            <Row >
            <Col md={6} data-aos="fade-right">
            <ul className='list-style-one'>
            <li>	Micro-services Architecture based Distributed System Architecture with cloud deployment and distributed database and file servers. </li>
            <li>	Hospital Management Information system.</li>
            <li>	Learning Management System for educational institutes.</li>
            <li>	Mobile Applications development and Mobile App store.</li>
            <li>	WEB based MIS and data management system.</li>
           

            </ul>
            </Col>
            <Col md={6} data-aos="fade-left">
            <ul className='list-style-one'>
           
            <li>	Mobile Voice call, call center/OBD/IVR/location-based services.</li>
            <li>	ERP modules for social, development & business sectors.</li>
            <li>	Knowledge management and program implementation.</li>
            <li>	Automated monitoring & evaluation system.</li>
            <li>	Online content creation, curation, monitoring, development and distribution.</li>

            </ul>
            </Col>
            </Row>
         </div>
         </Col>
       
         <Col md={12} data-aos="fade-left">
         <div className='about-company  mt-4 left-0-b'>
           
            <h3>
            EATL’s Growth & Strategic Revolution in ICT 
            </h3>
            <Row>
            <Col md={6} data-aos="fade-right">
            <ul className='list-style-one'>
	<li>EATL is currently working with BANBEIS and BUET team in developing the Integrated Educational Information Management System (IEIMS) project of Ministry of Education. </li>
	<li>EATL also began the project "Design, Development, Implementation and Maintenance of Learning Management System (LMS) for National University, Bangladesh", the development is currently ongoing and funded by the World Bank CEDP project.</li>
	<li>Recently, EATL began the project “ICT-based Students Competitions Management Consultant – Creating an enabling understanding among the students to discover green growth leaders for the next generations in Bangladesh” funded by the World Bank.</li>
	<li>EATL successfully completed the development of the Examination Management System (EMS) for National University Bangladesh with which 2300 colleges are affiliated. This paperless environment is serving over 3.5 million students, teachers, 2300+ colleges, staff and stakeholders of the National University, Bangladesh. </li>
	<li>EATL successfully completed the "Establish e-Library Management and Set Up Web Based Medical Student Facilities Management" project for the Directorate General of Medical Education (DGME) under Ministry of Health & Family Welfare (MoHFW), Bangladesh.</li>
	<li>EATL successfully completed the "Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh" project for Centre for Medical Education (CME) in collaboration with WHO. </li>
	<li>EATL completed the "Establishing Digital Birth and Death Notification System and Capacity Building in Health System for Strengthening EPI and CVRS in Bangladesh" project for MIS of Directorate General of Health Services (DGHS), Ministry of Health and Family Welfare (MoHFW). </li>
	<li>EATL-SZZT-KMMT-Synesis IT JV completed a nationwide project titled "Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), Sales Data Controller (SDC) and Managed Services" for National Board of Revenue (NBR) of Ministry of Finance of Bangladesh. The project is at present in maintenance and support phase.</li>
	<li>EATL completed the project "Development of Web Based Automation System" for the Educational Institutions & Directorate of Inspection and Audit (DIA. Through this automated system, DIA can monitor the overall activities of the 37,000 schools, colleges and educational institutions centrally.</li>
	


</ul>
            </Col>
            <Col md={6} data-aos="fade-left">
            <ul className='list-style-one'>
            <li>EATL completed the educational content development project for Secondary Education Sector Investment Program (SESIP) named "Development Of E-Learning Modules for Six Subjects" under Ministry of Education (MoE).</li>
	<li>EATL developed designed and completed 14 ERP modules and maintained real-time online Enterprise Resources Planning (ERP) solutions for establishing automated system for Essential Drug Company Limited (EDCL), Bangladesh. </li>
	<li>EATL successfully developed and supplied e-learning materials for nine subjects of class IX and X in partnership with the TQI-II project of DSHE, Ministry of Education (MoE).</li>
	<li>EATL developed a mobile application integrated with web MIS and provided technical support and training in a project funded DFID under Ministry of Health and Family Welfare in partnership with icddr,b.</li>
	<li>EATL implemented a project taken by the Secondary Education Quality and Access Enhancement Project (SEQAEP) that involved ever-largest web-based MIS systems in Bangladesh, covering 64 districts and 134 Upazilas with over 7000 schools for monitoring and evaluation of results and investments. </li>
	<li>EATL successfully ran 4 seasons of the app competition “EATL-Prothom Alo Mobile Apps Development Contest” where The Daily Prothom Alo was the title partner along with Ministry of ICT (MOICT), ROBI, Channel I and many top bodies from different sectors.</li>
	<li>EATL developed and launched the EATL mobile Apps Store in 2012 & branded as “EATLApps”. It was the first mobile apps store with Bangla apps, focused on local context & needs for Bangladesh.</li>
	<li>EATL developed the website and landing portal of PKSF Bangladesh.</li>
	<li>EATL developed the website of the Banga Bhaban, the residence of the President of Bangladesh as an early project of EATL. </li>
	<li>EATL is contributing in national goal of “Digital Bangladesh” for the last 22 years and now is fully committed to develop the “Smart Bangladesh” by 2041.</li>
	<li>EATL collaborates with govt. bodies, international NGOs and donor organizations, different MNCs, local media, top industry professionals and other vital organizations so that we can promote the potential talents to the right place.</li>

</ul>
            </Col>
            </Row>
           
         </div>
         </Col>
         </Row>
   </Container>
</Common>
);
};
export default About;
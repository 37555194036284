import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import { NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import cell from '../../../assets/images/cell.svg';
import sms from '../../../assets/images/sms.svg';
import app from '../../../assets/images/app.svg';
import customer from '../../../assets/images/customer.svg';
import consul from '../../../assets/images/consul.svg';
import daweb from '../../../assets/images/daweb.svg';
import ittr from '../../../assets/images/ittr.svg';
import cusS from '../../../assets/images/cusS.svg';
import locs from '../../../assets/images/locs.svg';
import moap from '../../../assets/images/moap.svg';



const Service = () => {
   return (
      <Common>
         <section className='sub-page-banner'>
            <Container>
               <Row>
                  <Col md={12} className='text-center sup-page-inner'>
                     <h2>Our Services </h2>
                     <Breadcrumb className='text-center custom-breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Services</Breadcrumb.Item>
                     </Breadcrumb>
                  </Col>
               </Row>
            </Container>
         </section>
         <Container className='mb-5 pt-5 mt-5 mtpt-custom'>
            <Row className='justify-content-md-center services-all-list'>
            <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-left"> <img src={app} alt="img" /> </div>
                     <h4> Customized Web Application </h4>
                     <p> EATL builds custom web applications according to your business logic. </p>
                     <span>
                        <NavLink exact to="/customized-web-application">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-right"> <img src={daweb} alt="daweb" /> </div>
                     <h4>Dynamic Web Site </h4>
                     <p> EATL offers services in appropriate web design, website hosting and web marketing solutions for small to medium sized businesses.</p>
                     <span>
                        <NavLink exact to="/dynamic-web">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-left"> <img src={ittr} alt="ittr" /> </div>
                     <h4>IT Training </h4>
                     <p> Build your career with our Hands-On IT Training and maximize your IT investment with EATL's technical trainings. </p>
                     <span>
                        <NavLink exact to="">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-left"> <img src={cusS} alt="cusS" /> </div>
                     <h4> Customized Software </h4>
                     <p>EATL is a custom software development company that provides customized softwares. It works exactly the way you want them to. </p>
                     <span>
                        <NavLink exact to="/customized-software">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3} >
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-right"> <img src={consul} alt="consul" /> </div>
                     <h4>IT Consultancy </h4>
                     <p> EATL has expert IT consultants and can provide a broad range of IT consultancy services as part of an end to end solution or a one off. </p>
                     <span>
                        <NavLink exact to="/iT-consultancy">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-right"> <img src={moap} alt="moap" /> </div>
                     <h4> Service based Mobile Apps</h4>
                     <p>MOBILE APPS
                        EATL provides Mobile Applications to support different government and non-government services, enabling people  </p>
                     <span>
                        <NavLink exact to="/mobile-apps">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-right"> <img src={cell} alt="img" /> </div>
                     <h4> OBD, Voice Call Broadcasting </h4>
                     <p> Broadcasting Pre-Recorded Voice More Efficiently. </p>
                     <span>
                        <NavLink exact to="/voice-call">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-right"> <img src={sms} alt="img" /> </div>
                     <h4> SMS Gateway – Bulk SMS </h4>
                     <p> Solution to send Single & Bulk-SMS Messages. </p>
                     <span>
                        <NavLink exact to="/sms-gateway">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
           
               <Col xs={12} md={6} lg={3}>
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-left"> <img src={customer} alt="img" /> </div>
                     <h4> Call Center Setup & Implementation </h4>
                     <p> Call Center Setup Consultation and Implementation. </p>
                     <span>
                        <NavLink exact to="/call-center-setup">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
         
         
          
               <Col xs={12} md={6} lg={3} >
                  <div className='service-box-five2'>
                     <div className='service-icon-five' data-aos="fade-right"> <img src={locs} alt="locs" /> </div>
                     <h4> LBS (Location Based Services) </h4>
                     <p> EATL Location-Based Services (LBS) provide an innovative way for you to meet your needs by enabling you to send relevant information. </p>
                     <span>
                        <NavLink exact to="/lbs">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
          

            </Row>
         </Container>
      </Common>
   );
};
export default Service;
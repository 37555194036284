import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import dmana from '../../../assets/images/dmana.jpg';



const WorkshopFoodDisasterManagement = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>Workshop with Food and Disaster Management</h5>
            <span className='date-title'>Dated : 12 December, 2013</span>
            <p className='line-h'>
            খাদ্য ও দুর্যোগ ব্যবস্থাপনা সংশ্লিষ্ট প্রতিষ্ঠানগুলোর জন্য মোবাইল এ্যাপ্লিকেশন আইডিয়া উদ্ভাবন কর্মশালা
            </p>


            <p className='line-h'>
            আজ সকাল ৯টায়, পল্টনের হাউজ বিল্ডিং ফাইনান্স কর্পোরেশনের অডিটরিয়ামে অনুষ্ঠিত হয়ে গেল খাদ্য ও দুর্যোগ ব্যবস্থাপনা সংশ্লিষ্ট প্রতিষ্ঠানগুলোর জন্য মোবাইল এ্যাপ্লিকেশন আইডিয়া ইনভেনশন কর্মশালা। “জাতীয় পর্যায়ে মোবাইল এ্যাপ্লিকেশন উন্নয়নে সচেতনতা ও দক্ষতা বৃদ্ধি কর্মসূচি” বাস্তবায়নের অংশ হিসেবে কর্মশালাটি আয়োজন করেন গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের তথ্য ও যোগাযোগ প্রযুক্তি মন্ত্রণালয়। অনুষ্ঠানে সূচনা বক্তব্য রাখেন তথ্য ও যোগাযোগ প্রযুক্তি মন্ত্রণালয়ের অতিরিক্ত সচিব কামাল উদ্দিন আহমেদ। পুরো আইডিয়া সেশনটি পরিচালনা করেন একই মন্ত্রণালয়ের সহকারী সচিব মিয়া মোহাম্মাদ কেয়ামউদ্দিন। এ কর্মশালায় খাদ্য এবং দুর্যোগ ব্যবস্থাপনা অধিদপ্তরের নিয়ন্ত্রণাধীন বিভিন্ন প্রতিষ্ঠানের আমন্ত্রিত অতিথিগণ অংশগ্রহণ করেন।
            </p>

            <p className='line-h'>
            সূচনা বক্তব্যের পর; মোবাইল এ্যাপস্ পরিচিতি, এর ব্যবহার এবং খাদ্য এবং দুর্যোগ ব্যবস্থাপনা অধিদপ্তর সংশ্লিষ্ট প্রতিষ্ঠানগুলোর জন্য কী ধরনের মোবাইল এ্যাপস্ তৈরী হতে পারে সে বিষয়ে প্রাথমিক ধারণাসহ প্রস্তাবনা রাখেন ইএটিএল-এর টেকনোলজি এডভাইজর ড. রাজেশ পালিত। এরপর আমন্ত্রিত কর্মকর্তাগণ একে একে তাদের প্রস্তাবনা উপস্থাপন করেন। এ সেশনে খাদ্য এবং দুর্যোগ ব্যবস্থাপনা অধিদপ্তরের সংশ্লিষ্ট প্রতিষ্ঠানসমূহ গ্রাহকদের কাছে আরও সহজ এবং সুস্পষ্টভাবে তাদের সেবা নিশ্চিত করার বিভিন্ন মাধ্যম নিয়ে আলোচনা করেন। তথ্য ও প্রযুক্তি মন্ত্রণালয়ের সহকারী সচিব মিয়া মোহাম্মাদ কেয়ামউদ্দিন একে একে সবার উপস্থাপনা শোনেন এবং পুরো আইডিয়া সেশনটি পরিচালনা করেন।
            </p>

            <p className='line-h'>
            এই সকল উন্মুক্ত আইডিয়া থেকে নির্বাচিত আইডিয়াসমূহকে গ্রহণ করে পরবর্তীতে মোবাইল এ্যাপস নির্মাণ করা হবে এবং পূর্ণাঙ্গ আইডিয়া সমূহকে উন্মুক্ত একটি ওয়েব সাইটে আইডিয়া রিসোর্স হিসেবে প্রকাশ করা হবে। এই প্রকল্পের আওতায় এই সকল আইডিয়া নিয়ে একটি প্রকাশনাও তৈরি করা হবে। 
            </p>
         </div>
         </Col>
         <Col md={6} data-aos="fade-left">
         <img className='news-details' src={dmana} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default WorkshopFoodDisasterManagement;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import weba from '../../../assets/images/weba.png';
import weba2 from '../../../assets/images/weba2.png';
const CustomizedWebApplication = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Customized Web Application</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Customized Web Application</h5>
                            <p className='line-h'>
                                On web based customized solutions, EATL has expertise in providing several types of solutions in Bangladesh as several application were developed for different sectors i.e. pharmaceutical sector, health sector, education sector etc. Especially, ERP solutions, Asset Management System etc. are some the elegant web based customized solutions that EATL provided to multiple clients as part of bundle of solutions.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-right">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={weba} alt="weba" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={weba2} alt="weba2" />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className='about-company left-0 mt-2'>
                            <p className='line-h mt-5'>
                                EATL has the experience to develop these solutions both in .NET and PHP using different frameworks. Some of the solutions are mentioned below:
                            </p>
                            <h5 className=''>
                                Management Information system for the Ministry of Education:
                            </h5>
                            <p className='line-h'>
                                Actually this is a WebMIS developed for Secondary Education Quality and Access Enhancement Project (SEQAEP), Ministry of education, Government of the Peoples’ Republic of Bangladesh covering 124 upazillas of Bangladesh. This application was customized to support a vast range of services for ensuring the quality of secondary education in different schools of Bangladesh
                            </p>
                            <h5 className=''>
                                Community Clinic Project for the Ministry of Health:
                            </h5>
                            <p className='line-h'>
                                This is completely online and accessed from the different geographical locations of Bangladesh including 12300 CCs. Ministry of Health, Government of the Peoples’ Republic of Bangladesh is the client of EATL for this web based application
                            </p>
                            <h5 className=''>
                                Appointment Management System for the Bangabhaban:
                            </h5>
                            <p className='line-h'>
                                It is a website and appointment management system for the office of the Honourable President of the Government of the Peoples’ Republic of Bangladesh
                            </p>
                            <h5 className=''>
                                Hospital Information Management System for IMCH:
                            </h5>
                            <p className='line-h'>
                                This product is functional in International Medical College and Hospital (IMCH), Tongi which is an online MIS. It manages the functionalities of any large medical college and hospital and its branches.
                            </p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default CustomizedWebApplication;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Common from "../../layouts/Common";
import { FaPeriscope, FaRegEnvelope,FaMobileAlt } from 'react-icons/fa';

import '../contact/contact.css';

const Contact = () => {
    return (
        <Common>
        <section className='sub-page-banner contact-bg'>
           <Container>
              <Row>
                 <Col md={12} className='text-center sup-page-inner'>
                 <h2>Contact</h2>
                 <Breadcrumb className='text-center custom-breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                    Contact
                    </Breadcrumb.Item>
                 </Breadcrumb>
                 </Col>
              </Row>
           </Container>
        </section>
        <Container>
           <Row className='column-reverse2'>
              <Col  md={12} lg={5} data-aos="fade-right">
              <div className="contact-box">
  <div className="sec-title mb-45"> 
    <h2 className="title white-color">Let's Contact With Us</h2>
  </div>
  <div className="address-box mb-25">
    <div className="address-icon">  <FaMobileAlt/> </div>
    <div className="address-text"> <span className="label">Phone:</span> <a href="tel:09614-444000">+88-09614-444000</a> </div>
  </div>
  <div className="address-box mb-25">
    <div className="address-icon">  <FaRegEnvelope/> </div>
    <div className="address-text"> <span className="label">Email:</span> <a href="#">info@eatlbd.com</a> </div>
  </div>
  <div className="address-box">
    <div className="address-icon"> <FaPeriscope/> </div>
    <div className="address-text"> <span className="label">Address:</span>
      <div className="desc">Corporate Office: Rupayan Shopping Square, Level-5, Plot-2, Block-G, Sayem Sobhan Anvir Road, Bashundhara R/A, Dhaka-1229, Bangladesh.</div>
    </div>
  </div>
</div>
              </Col>
              <Col md={12} lg={7}  className='pl-70' data-aos="fade-left">
              <div className="contact-widget">
  <div className="sec-title2 mb-40"> <span className="sub-text contact mb-15">Get In Touch</span>
    <h2 className="title testi-title text-start">Fill The Form Below</h2>
  </div>
  <div id="form-messages"></div>
  <div id="contact-form" method="post" action="mailer.php">
  
      <Row> 
        <Col md={6} className="mb-30">
          <input className="from-control" type="text" id="name" name="name" placeholder="Name" required />
        </Col>
        <Col md={6} className="mb-30">
          <input className="from-control" type="text" id="email" name="email" placeholder="E-Mail" required />
        </Col>
        <Col md={6} className="mb-30">
          <input className="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required />
        </Col>
         <Col md={6} className="mb-30">
          <input className="from-control" type="text" id="website" name="website" placeholder="Your Subject" required />
        </Col>
          <Col md={12} className="mb-30">
          <textarea className="from-control" id="message" name="message" placeholder="Your message Here" required></textarea>
        </Col>
      </Row>
      <div className="btn-part">
        <div className="form-group mb-0">
          <input className="readon learn-more submit" type="submit" value="Submit Now" />
        </div>
      </div>
 
  </div>
</div>
              </Col>
           
           </Row>
        </Container>

        <section className='google-map'>
        <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.8515018041317!2d90.42702492605972!3d23.823879035950142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c79e609ca88b%3A0xfb4ce8cee33a3173!2sEthics%20Advance%20Technology%20Limited!5e0!3m2!1sen!2sbd!4v1694500240888!5m2!1sen!2sbd"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
     </Common>
            
          
      
    );
};

export default Contact;
import React from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import Nofound from './pages/not-found/Nofound';
import ScrollToTop from './common/ScrollTop';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import About from './pages/about/About';
// services
import VoiceCall from './pages/services/voice-call/VoiceCall';
import SMSGateway from './pages/services/sms-gateway/SMSGateway';
import CallCenterSetup from './pages/services/call-center-setup/CallCenterSetup';
import ITConsultancy from './pages/services/iT-consultancy/ITConsultancy';
import DynamicWeb from './pages/services/dynamic-web/DynamicWeb';
import LBS from './pages/services/lbs/LBS';
import MobileApps from './pages/services/mobile-apps/MobileApps';
import CustomizedSoftware from './pages/services/customized-software/CustomizedSoftware';
import CustomizedWebApplication from './pages/services/customized-web-application/CustomizedWebApplication';
// products
import MobileApp from './pages/products/mobile-app/MobileApp';
import FileTrackingSystem from './pages/products/file-tracking-system/FileTrackingSystem';
import ERPPharma from './pages/products/ERP-pharma/ERPPharma';
import WebHIMS from './pages/products/webHIMS/WebHIMS';
import HRMS from './pages/products/hrms/HRMS';
import WebMIS from './pages/products/web-MIS/WebMIS';
import ClinicBasedMIS from './pages/products/clinic-based-mis/ClinicBasedMIS';
import Edutubeproduct from './pages/products/edutube/Edutube';
import MdDetails from './pages/md-details/MdDetails';
import Service from './pages/services/services-list/Service';
import ProductsList from './pages/products/products-list/ProductsList';
// projects
import Projects from './pages/projects/Projects';
import NationalUniversity from './pages/projects/national-university/NationalUniversity';
import Nbr from './pages/projects/nbr/Nbr';
import Dia from './pages/projects/dia/Dia';
import EFDMS from './pages/projects/efdms/EFDMS';
import ELibrary from './pages/projects/e-Library/ELibrary';
import Edutube from './pages/projects/edutube/Edutube';
import IEIMS from './pages/projects/ieims/IEIMS';
import CompetitionsManagement from './pages/projects/competitions/CompetitionsManagement';
import LMS from './pages/projects/lms/LMS';
import DGHS from './pages/projects/dghs/DGHS';
import SESIP from './pages/projects/sesip/SESIP';
import CME from './pages/projects/cme/CME';

import PartnersClients from './pages/partners-clients/PartnersClients';
import Newsroom from './pages/newsroom/Newsroom';
import Elearning from './pages/newsroom/elearning/Elearning';
import DigitalBook from './pages/newsroom/digital-book/DigitalBook';
import IctMobileApp from './pages/newsroom/ict-mobile-app/IctMobileApp';
import BracUniversity from './pages/newsroom/brac-uni/BracUniversity';
import TrainingProgram from './pages/newsroom/training-program/TrainingProgram';
import ClosingCeremony from './pages/newsroom/closing-ceremony/ClosingCeremony';
import MouSignedNsu from './pages/newsroom/mou-signed-nsu/MouSignedNsu';
import DigitalBirthCertificate from './pages/newsroom/digital-birth-certificate/DigitalBirthCertificate';
import TrainingProgramUits from './pages/newsroom/training-program-uits/TrainingProgramUits';
import EatlSignedAiub from './pages/newsroom/eatl-signed-aiub/EatlSignedAiub';
import MouSigningEatlUap from './pages/newsroom/mou-signing-eatl-uap/MouSigningEatlUap';
import TrainingProgramDiu from './pages/newsroom/training-program-diu/TrainingProgramDiu';
import WorkshopMinistryEducation from './pages/newsroom/workshop-ministry-education/WorkshopMinistryEducation';
import WorkshopFoodDisasterManagement from './pages/newsroom/workshop-food-disaster-management/WorkshopFoodDisasterManagement';
import WorkshopMinistryAgriculture from './pages/newsroom/workshop-with-ministry-agriculture/WorkshopMinistryAgriculture';
import MouSignedDiu from './pages/newsroom/mou-signed-diu/MouSignedDiu';
import HealthFamilyWelfare from './pages/newsroom/health-family-welfare/HealthFamilyWelfare';
import MouMobileApplicationDevelopment from './pages/newsroom/mou-mobile-application-development/MouMobileApplicationDevelopment';
import ProthomAloApp from './pages/newsroom/media-section/ProthomAloApp';
import MobileAppsBangla from './pages/newsroom/media-section/MobileAppsBangla';
import NewYear from './pages/newsroom/media-section/NewYear';
import GpSubscribers from './pages/newsroom/media-section/GpSubscribers';
import ProthomAloResult from './pages/newsroom/media-section/ProthomAloResult';
import Eatl26App from './pages/newsroom/media-section/Eatl26App';
import Top10App from './pages/newsroom/media-section/Top10App';
import TopTenAvailable from './pages/newsroom/media-section/TopTenAvailable';
import NewAppleSupport from './pages/newsroom/media-section/NewAppleSupport';
import TouchOfLove from './pages/newsroom/media-section/TouchOfLove';
import AgreementWithEatl from './pages/newsroom/media-section/AgreementWithEatl';
import InnovateMobileApps from './pages/newsroom/media-section/InnovateMobileApps';
import TwentyOneApp from './pages/newsroom/media-section/TwentyOneApp';
import ProthomaloAppsCompetition from './pages/newsroom/media-section/ProthomaloAppsCompetition';
import BracUn from './pages/newsroom/media-section/BracUn';
import SecondPhaseResults from './pages/newsroom/media-section/SecondPhaseResults';
import UapStudentApp from './pages/newsroom/media-section/UapStudentApp';
import AppsDevelopmentWorkshop2 from './pages/newsroom/media-section/AppsDevelopmentWorkshop2';
import ConceptPapersAppsCompetition from './pages/newsroom/media-section/ConceptPapersAppsCompetition';
import SahjalalUniversity from './pages/newsroom/media-section/SahjalalUniversity';
import CampusActivationRu from './pages/newsroom/media-section/CampusActivationRu';
import EatlAppsContestProthomAlo2 from './pages/newsroom/media-section/EatlAppsContestProthomAlo2';

import Gallery from './pages/gallery/Gallery';
import Vlog from './pages/vlog/Vlog';

import InnovationHub from './pages/innovation-hub/InnovationHub';



const Routing = () => {
    return (



      <HashRouter hashType='noslash'>
      <ScrollToTop />
      <Routes>
        <Route>
          <Route index element={<Home />} />
            <Route path="home" element={<Home/>} />
            <Route path="about-us" element={<About/>} />
            <Route path="voice-call" element={<VoiceCall/>} />
            <Route path="sms-gateway" element={<SMSGateway/>} />
            <Route path="call-center-setup" element={<CallCenterSetup/>} />
            <Route path="iT-consultancy" element={<ITConsultancy/>} />
            <Route path="dynamic-web" element={<DynamicWeb/>} />
            <Route path="customized-software" element={<CustomizedSoftware/>} />
            <Route path="customized-web-application" element={<CustomizedWebApplication/>} />
            <Route path="lbs" element={<LBS/>} />
            <Route path="mobile-apps" element={<MobileApps/>} />
            <Route path="ERP-pharma" element={<ERPPharma/>} />
            <Route path="mobile-app" element={<MobileApp/>} />
            <Route path="webHIMS" element={<WebHIMS/>} />
            <Route path="hrms" element={<HRMS/>} />
            <Route path="web-MIS" element={<WebMIS/>} />
            <Route path="clinic-based-mis" element={<ClinicBasedMIS/>} />
            <Route path="edutube" element={<Edutubeproduct/>} />
            <Route path="file-tracking-system" element={<FileTrackingSystem/>} />
            <Route path="md-details" element={<MdDetails/>} />
            <Route path="services" element={<Service/>} />
            <Route path="products-list" element={<ProductsList/>} />
            <Route path="projects" element={<Projects/>} />
            <Route path="edutube" element={<Edutube/>} />
            <Route path="national-university" element={<NationalUniversity/>} />
            <Route path="nbr" element={<Nbr/>} />
            <Route path="dia" element={<Dia/>} />
            <Route path="efdms" element={<EFDMS/>} />
            <Route path="e-Library" element={<ELibrary/>} />
            <Route path="ieims" element={<IEIMS/>} />
            <Route path="competitions" element={<CompetitionsManagement/>} />
            <Route path="lms" element={<LMS/>} />
            <Route path="dghs" element={<DGHS/>} />
            <Route path="sesip" element={<SESIP/>} />
            <Route path="cme" element={<CME/>} />
            <Route path="partners-clients" element={<PartnersClients/>} />
            <Route path="newsroom" element={<Newsroom/>} />
            <Route path="elearning" element={<Elearning/>} />
            <Route path="digital-book" element={<DigitalBook/>} />
            <Route path="ict-mobile-app" element={<IctMobileApp/>} />
            <Route path="signed-brac-university" element={<BracUniversity/>} />
            <Route path="training-program" element={<TrainingProgram/>} />
            <Route path="closing-ceremony" element={<ClosingCeremony/>} />
            <Route path="mou-signed-nsu" element={<MouSignedNsu/>} />
            <Route path="digital-birth-certificate" element={<DigitalBirthCertificate/>} />
            <Route path="training-program-uits" element={<TrainingProgramUits/>} />
            <Route path="eatl-signed-aiub" element={<EatlSignedAiub/>} />
            <Route path="mou-signing-eatl-uap" element={<MouSigningEatlUap/>} />
            <Route path="training-program-diu" element={<TrainingProgramDiu/>} />
            <Route path="workshop-ministry-education" element={<WorkshopMinistryEducation/>} />
            <Route path="workshop-food-disaster-management" element={<WorkshopFoodDisasterManagement/>} />
            <Route path="workshop-with-ministry-agriculture" element={<WorkshopMinistryAgriculture/>} />
            <Route path="mou-signed-diu" element={<MouSignedDiu/>} />
            <Route path="health-family-welfare" element={<HealthFamilyWelfare/>} />
            <Route path="mou-mobile-application-development" element={<MouMobileApplicationDevelopment/>} />
            <Route path="new-year" element={<NewYear/>} />
            <Route path="gp-subscribers" element={<GpSubscribers/>} />
            <Route path="prothom-alo-result" element={<ProthomAloResult/>} />
            <Route path="eatl-26-mobile-app" element={<Eatl26App/>} />
            <Route path="top-10-apps" element={<Top10App/>} />
            <Route path="top-ten-available" element={<TopTenAvailable/>} />
            <Route path="new-apple-support" element={<NewAppleSupport/>} />
            <Route path="touch-of-love" element={<TouchOfLove/>} />
            <Route path="agreement-with-eatl" element={<AgreementWithEatl/>} />
            <Route path="innovate-mobile-apps" element={<InnovateMobileApps/>} />
            <Route path="twenty-one-app" element={<TwentyOneApp/>} />
            <Route path="prothomalo-apps-competition" element={<ProthomaloAppsCompetition/>} />
            <Route path="agreemen-with-brac-university" element={<BracUn/>} />
            <Route path="second-phase-results-announced" element={<SecondPhaseResults/>} />
            <Route path="uap-student-develops-new-app" element={<UapStudentApp/>} />
            <Route path="apps-development-workshop" element={<AppsDevelopmentWorkshop2/>} />
            <Route path="concept-papers-apps-competition" element={<ConceptPapersAppsCompetition/>} />
            <Route path="sahjalal-universit-activation-news" element={<SahjalalUniversity/>} />
            <Route path="campus-activation-ru" element={<CampusActivationRu/>} />
            <Route path="eatl-apps-contest-prothomalo" element={<EatlAppsContestProthomAlo2/>} />
            <Route path="eatl-innovation-hub" element={<InnovationHub/>} />

            
          {/* media section */}
          <Route path="prothom-alo-app" element={<ProthomAloApp/>} />
          <Route path="mobile-apps-bangla" element={<MobileAppsBangla/>} />
          

            <Route path="gallery" element={<Gallery/>} />
            <Route path="vlog" element={<Vlog/>} />
            
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<Nofound />} />

        </Route>
      </Routes>
    </HashRouter>

     
              
    );
};

export default Routing;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import csoft from '../../../assets/images/csoft.png';
import cos2 from '../../../assets/images/cos2.png';
const CustomizedSoftware = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Customized Software</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Customized Software</h5>
                            <p className='line-h'>
                                EATL is a custom software development company that provides customized software. It works exactly the way you want them to.
                            </p>
                            <p className='line-h'>
                                Differences in user interfaces of custom software solutions may be significant ranging from one industry to another. But the main targets remain unchanged – enhancement of internal operations, accountability, efficiency due to use of intuitive software with easy-to-use and friendly user interfaces. The characteristic features and functionalities of solutions are determined by standards of the industry.
                            </p>
                        </div>
                    </Col>
                
                    <Col md={6} data-aos="fade-right">
                        <Row className=' mt-70 '>
                            <Col md={6}>
                                <img className='services-box' src={csoft} alt="csoft" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={cos2} alt="cos2" />
                            </Col>


                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-leftt">
                        <div className='about-company mt-4 left-0'>
                            <h3>
                                What You Need in Custom Software Solutions
                            </h3>
                            <p className=' line-h'>
                                Custom software solutions may have industry specific features in the sets of interface features. Dependent on the kind of activities, different businesses may require integrated knowledge of safety standards, volatility of product or government issues. Design of layouts and container maps for packaging industry requires maximum of flexibility and accuracy. Thus such specific elements condition the implementation of functionalities which will facilitate proper product design and accountability.
                            </p>
                            <p className=' line-h'>
                                It is anticipated that custom software solutions, as any other software, will evolve in the context of streamlining and automating complex processes.
                            </p>
                        </div>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className=' left-0'>
                            <h3 className='mt-2'>
                                Advantages:
                            </h3>
                            <ul className='list-style-one'>
                                <li>Industry specific features and functionalities, use of multiple networks and systems integration.</li>
                                <li>The application is built to your requirements, you’ll save time and money when it comes to teaching your staff how to use it.</li>
                                <li>An application developed according to your own specification will probably require much less configuration work and much less training. It’s replicating your existing business process(es) and should be intuitive to your staff.</li>
                                <li>A good custom application can also bring efficiencies by automating manual tasks and a good developer can even point out further efficiencies to be gained.</li>
                                <li>Custom-made software was once the preserve of large companies, but the cost of developing custom-made applications has fallen because of robust application frameworks, a vast array of off-the-shelf modules and rapid development tools, putting it within reach of SMEs.</li>
                                <li>Custom solutions possess notable degree of flexibility which will add to the infrastructure of your business and allow for comprehensive level of customization.</li>
                            </ul>

                        </div>

                    </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default CustomizedSoftware;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import smsg from '../../../assets/images/smsg.png';
import smsg2 from '../../../assets/images/smsg2.png';
const SMSGateway = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>SMS Gateway – Bulk SMS</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>SMS Gateway – Bulk SMS</h5>
                            <p className='line-h'>
                                We offer customers to access a secure, streamline, simple, easy to use and dependable high-capacity messaging platform. Our interactive service is provided via a direct, dedicated link into the all Bangladeshi Mobile network operators.
                            </p>
                            <p className='line-h'>
                                Our service enables you to send same or customized SMS to single or multiple users as per your need and demand. The phone number or short code can be masked which means you can rename the phone number or short code in any of the name or word you prefer.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left">
                        <div className='about-company mt-70'>
                            <h3 className='mt-5'>
                                Advantages:
                            </h3>
                            <p className='line-h'>This web-based messaging service allows you to:</p>
                            <ul className='list-style-one'>
                                <li>Easy and reliable message delivery</li>
                                <li>Send bulk SMS’s direct from your web browser</li>
                                <li>Conduct history reports</li>
                                <li>Conduct bulk imports of contacts</li>
                                <li>Send SMS’s to individuals or groups directly to their phone</li>
                                <li> And many advanced sending features</li>
                                <li>Track all necessary reports on bulk message service</li>
                            </ul>

                        </div>

                    </Col>
                    <Col md={6} data-aos="fade-right">
                        <Row>
                            <Col md={6}>
                                <img className='services-box' src={smsg} alt="smsg" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={smsg2} alt="smsg2" />
                            </Col>
                            <p className='mt-3 line-h'>Let the advantages of SMS Media grow your business</p>
                            <p className=' line-h'>
                                SMS media is a highly effective channel, largely due to the fact that over 98% of SMS’s received are viewed. The high performance of the medium is based on the following unique characteristics:
                            </p>
                            <ul className='list-style-one'>
                                <li> A mobile phone is a more personal environment than direct mail or e-mail</li>
                                <li> SMS media has little commercial clutter and much higher standout</li>
                                <li> SMS campaigns can be targeted more precisely by a combination of demographics such as location, age, income, education, race and experience</li>
                                <li> SMS marketing is a relatively new medium</li>
                                <li> SMS's are automatically stored and can be referred to or used at a more appropriate time</li>
                            </ul>

                        </Row>
                    </Col>
                    <Col md={6} data-aos="fade-leftt">
                        <div className='about-company mt-4'>
                            <h3>
                                Feature:
                            </h3>
                            <p className=' line-h'>
                                EATL’s Bulk SMS product helps you with the following features:
                            </p>
                            <ul className='list-style-one'>
                                <li>Import/enter contacts and manage them into convenient groups</li>
                                <li>Personalize your messages</li>
                                <li>Monitor and analyze your messaging campaigns</li>
                                <li>Download history reports in MS Excel format</li>
                                <li>Market instantly and directly to your customers or group
                                    members, or alert them of offers, deals, meetings or changes in arrangements</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default SMSGateway;
import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import m34 from "../../../assets/images/m34.jpg";
import m35 from "../../../assets/images/m35.jpg";
import m36 from "../../../assets/images/m36.jpg";
import m37 from "../../../assets/images/m37.jpg";
import m38 from "../../../assets/images/m38.jpg";
import m39 from "../../../assets/images/m39.jpg";
import m40 from "../../../assets/images/m40.jpg";

import m41 from "../../../assets/images/m41.jpg";
import m42 from "../../../assets/images/m42.jpg";
import m43 from "../../../assets/images/m43.jpg";
import m44 from "../../../assets/images/m44.jpg";
import m45 from "../../../assets/images/m45.jpg";
import m46 from "../../../assets/images/m46.jpg";
import m47 from "../../../assets/images/m47.jpg";
import m48 from "../../../assets/images/m48.jpg";
import m49 from "../../../assets/images/m49.jpg";
import m50 from "../../../assets/images/m50.jpg";
import m51 from "../../../assets/images/m51.jpg";



const GpSubscribers = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 08 April, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${m34}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m35}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m36}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m37}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },

              {
                src: `${m38}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                title:
                "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m39}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },

              {
                src: `${m40}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },

              {
                src: `${m41}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m42}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m43}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m44}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m45}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m46}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m47}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m48}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m49}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m50}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              {
                src: `${m51}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers’",
              },
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default GpSubscribers;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import sesip from '../../../assets/images/sesip.png';

const SESIP = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Contract for Consultant’s Services for The Development Of E-Learning Modules for Six Subjects</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>
                                Contract for Consultant’s Services for The Development Of E-Learning Modules for Six Subjects          </h5>
                            <p>
                                EATL completed the educational content development project for the Secondary Education Sector Investment Program (SESIP) named "Development Of E-Learning Modules for Six Subjects" in March 2019, and it was started in September 2017. The total value of this project was $350 Thousand USD.
                            </p>

                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                Secondary Education Sector Investment Program (SESIP)
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={sesip} alt="sesip" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default SESIP;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import voicec from '../../../assets/images/voicec.jpg';
import voic2 from '../../../assets/images/voic2.jpg';
const VoiceCall = () => {
   return (
      <Common>
         <section className='sub-page-banner'>
            <Container>
               <Row>
                  <Col md={12} className='text-center sup-page-inner'>
                     <h2>OBD, Voice Call Broadcasting</h2>
                     <Breadcrumb className='text-center custom-breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Service</Breadcrumb.Item>
                     </Breadcrumb>
                  </Col>
               </Row>
            </Container>
         </section>
         <Container className='mb-5'>
            <Row>
               <Col md={6} data-aos="fade-right">
                  <div className='about-company mt-70 left-0'>
                     <h5>OBD, Voice Call Broadcasting</h5>
                     <p className='line-h'>
                        EATL's OBD service gives the power to create target-based marketing campaigns rapidly and cost-effectively in the mobile media. The Outbound Dialer solution is a marketing and advertising platform that manages reports and optimizes all types of consumer-brand communications on the mobile handset. The advantages of this solution are Consumer profiling and targeting, rule-based campaign logic, integrated browsing/messaging mechanics, rich media and end-to-end reporting and analytics.
                     </p>
                  </div>
               </Col>
               <Col md={6} data-aos="fade-left">
                  <div className='about-company mt-70'>
                     <h3 className='mt-5'>
                        Key Benefits:
                     </h3>
                     <ul className='list-style-one'>
                        <li>  An effective Mobile based voice Campaign Tool</li>
                        <li>Provides fast, effective and personalized communication</li>
                        <li>Campaigns can be launched with minimal effort</li>
                        <li> Campaigns can be designed in any languages</li>
                        <li> No linguistic / education barrier as user only has to listen to it</li>
                        <li> Higher reach-ability in remote areas due to widespread telecom presence</li>
                     </ul>
                  </div>
               </Col>
               <Col md={6} data-aos="fade-right">
                  <Row>
                     <Col md={6}>
                        <img className='services-box' src={voicec} alt="voicec" />
                     </Col>
                     <Col md={6}>
                        <img className='services-box' src={voic2} alt="voic2" />
                     </Col>
                     <p className='mt-3 line-h'>
                        The solution is a powerful open ended platform, which helps deliver information over voice channel. Authorized personal can upload the information and can deliver it over voice channel by dialing to subscribers. The OBD solution can be used to do Online promotions. External systems can trigger OBD platform based on some predefined events (called trigger points). When the OBD platform receives the trigger, it validates the information passed from external system. Once the validation is successful, the OBD platform dials out the customer/s with pre-defined message.
                     </p>

                  </Row>
               </Col>
               <Col md={6} data-aos="fade-left">
                  <Row>
                     <div className='about-company mt-2'>
                        <h3>
                           This feature can be used for:
                        </h3>
                        <ul className='list-style-one'>
                           <li> Awareness campaign</li>
                           <li>Sending Birthday Greetings,</li>
                           <li>Anniversary Wishes,</li>
                           <li>Up selling of premium contents/packs,</li>
                           <li> Bill Explanation, Other such proactive marketing initiatives.</li>
                        </ul>
                     </div>
                  </Row>
               </Col>
            </Row>
         </Container>
      </Common>
   );
};
export default VoiceCall;
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import '../../common/nav/Nav.css';
import { FaHome} from 'react-icons/fa';
import mainlogo from '../../assets/images/mainlogo.svg';
import mailicon from '../../assets/images/mailicon.png';
import telephone from '../../assets/images/telephone.png';
import licon from '../../assets/images/licon.png';


const Navtop = () => {
  const [stickyClass, setStickyClass] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };


    return (
<>
<div className="tolp-header">
<Container>
<Row>
    <Col md={4}>
    <img className='logo-top' src={mainlogo} alt="logo" />
    </Col>
    <Col md={8} className='text-end topbar-responsive'>
    <ul className="rs-contact-info">
  <li className="contact-part"> 
  <img src={licon} alt="logo" />
  <span className="contact-info"> <span>Address</span> Rupayan Shopping Square, Level-5<br/> Bashundhara R/A, Dhaka-1229,</span> </li>
  <li className="contact-part">
  <img src={mailicon} alt="logo" />
     <span className="contact-info"> <span>E-mail</span> info@eatlbd.com</span> </li>
  <li className="contact-part border-end-0">
  <img src={telephone} alt="logo" />
     <span className="contact-info"> <span>Phone</span> +88-09614-444000. </span> </li>
</ul>
      </Col>
       </Row>
</Container>
</div>
  <Navbar className={`customHeader ${stickyClass}`} expand="lg" >
    <Container className='position-relative'>
    <Row>
    <Col md={12}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto custom-navbar">
            <NavLink exact to="/home">
              <FaHome className="me-1 home-mar"/>
              Home</NavLink>
              {/* <NavLink exact to="/about-us">About</NavLink> */}

            <NavDropdown title="About" id="basic-nav-dropdown" className="all-drop-down drop-down-nav custom-navbar-list">
            <NavDropdown.Item>
              <NavLink exact to="/about-us" >About The EATL</NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink exact to="/management" >Management</NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink exact to="/team">Team</NavLink>
            </NavDropdown.Item>
            </NavDropdown>
         
         
            <NavLink exact to="/services">Services</NavLink>
            <NavLink exact to="/products-list">Products</NavLink>
            <NavLink exact to="/projects">Projects</NavLink>
            <NavLink exact to="/partners-clients">Partners & Clients</NavLink>
            <NavLink exact to="/newsroom">Newsroom</NavLink>
            <NavLink exact to="/gallery">Gallery</NavLink>
            <NavLink exact to="/vlog">Vlog</NavLink>

            <NavLink exact to="/contact">Contact</NavLink>
            <NavLink className="invlink" exact to="/eatl-innovation-hub"><button className='btn btn-eatlhu'>Innovation HUB</button></NavLink>
          </Nav>
        </Navbar.Collapse>
        </Col>
       </Row>
    
    </Container>
  </Navbar>
 

</>
);
};

export default Navtop; 













import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import nua from '../../../assets/images/nua.png';
import nu from '../../../assets/images/nu.png';
const NationalUniversity = () => {
   return (
      <Common>
         <section className='sub-page-banner'>
            <Container>
               <Row>
                  <Col md={12} className='text-center sup-page-inner'>
                     <h2>Development and implementation of Examination Management System for National University</h2>
                     <Breadcrumb className='text-center custom-breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Projects</Breadcrumb.Item>
                     </Breadcrumb>
                  </Col>
               </Row>
            </Container>
         </section>
         <Container className='mb-5'>
            <Row>
               <Col md={8}>
                  <div className='about-company mt-70 left-0' data-aos="fade-right">
                     <h5>Development and implementation of Examination Management System for National University</h5>
                     <p>
                        In May 2019, EATL began the project "Development and Implementation of Examination Management System for National University", which was successfully completed in June 2021. Currently, operations and maintenance are underway. The total value of this project was $420 Thousand USD. The goal of the Examination Management System (EMS) was to automate the entire process of the Examination Division of the National University.                     </p>

                     <h5 className='mt-4'>Name of Client:</h5>
                     <p>National University</p>
                  </div>

               </Col>
               <Col md={4} data-aos=" about-company mt-70 fade-left">

                  <div class="row">
                     <div class="col-md-6">
                        <img className='nu-all mt-5' src={nua} alt="nua" />
                     </div>
                  </div>
               </Col>

            </Row>
         </Container>
      </Common>
   );
};
export default NationalUniversity;
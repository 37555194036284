import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import diu from '../../../assets/images/diu.jpg';



const TrainingProgramDiu = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>Android Apps Development Training Program at DIU</h5>
            <span className='date-title'>Dated : 30 November, 2013</span>
            <p className='line-h'>
            EATL started training in Daffodil University on Mobile application development, this project is financed by MOICT. Students from different universities are participating in this 5-day course for building skills and practical training for mobile apps development. Mr. Nazrul Islam Khan, Secretary of MOICT inaugurated the training session. Prof. Dr. Lutfor Rahman, VC of Daffodil University, Prof. Dr. Syed Akhter Hossain, Head of CSE, Dr. Mohammad Abul Hasan, Project Director from MOICT, Mr. M A Mubin Khan, Chairman of EATL, Dr. Nizam Uddin Ahmed, CEO of EATL and other professionals were present. This is an excellent initiative for building skills and offer opportunity for innovation and entrepreneurship. MOICT is funding this nation-wide programme which is implemented by EATL and MCC.            </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={diu} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default TrainingProgramDiu;
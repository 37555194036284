import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import dghs from '../../../assets/images/dghs.jpg';

const DGHS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>
                                Establishing a Digital Birth and Death Notification System and Capacity Building in Health System for Strengthening EPI and CVRS in Bangladesh                            </h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>
                                Establishing a Digital Birth and Death Notification System and Capacity Building in Health System for Strengthening EPI and CVRS in Bangladesh                            </h5>
                            <p>
                                EATL completed the "Establishing Digital Birth and Death Notification System and Capacity Building in Health System for Strengthening EPI and CVRS in Bangladesh" project for MIS of Directorate General of Health Services (DGHS), Ministry of Health and Family Welfare (MoHFW) in June 2020. The total value of this project was $1.1 million USD. This was a gradual national scale rollout for the overall strengthening of immunizing coverage and CRVS in Bangladesh to achieve health-related SDGs.
                            </p>
                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                Management Information System (MIS)
                                Directorate General of Health Services (DGHS)

                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={dghs} alt="dghs" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default DGHS;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import webd from '../../../assets/images/webd.png';
import webs2 from '../../../assets/images/webs2.png';
const DynamicWeb = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Dynamic Web Site</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Dynamic Web Site</h5>
                            <p className='line-h'>
                                The web development team of EATL is aimed to design and develop world class websites. EATL has a team of extraordinary programmers and graphics designers and we are able to handle any kind of website problems.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left ">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={webd} alt="webd" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={webs2} alt="webs2" />
                            </Col>

                        </Row>
                    </Col>

                    <Col md={6} data-aos="fade-left">
                        <div className='about-company left-0 mt-4'>
                            <h3>
                                Dynamic website can include:
                            </h3>
                            <ul className='list-style-one'>
                                <li>Customized user areas so that your clients or customers can see only the information, prices, or data that is relevant to them</li>
                                <li>Easily updatable dynamic product catalogues with images and thumbnail gallery.</li>
                                <li>Information pages that are updated in real-time.</li>
                                <li>Seamless integration of eCommerce  and payment gateways so users can buy online at customized prices</li>
                                <li>Seamless integration into a customized CMS.</li>
                                <li>Ajax based application which will work like gmail, google map.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default DynamicWeb;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import aiub from '../../../assets/images/aiub.jpg';



const EatlSignedAiub = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>EATL has signed an agreement with American International University – Bangladesh (AIUB)</h5>
            <span className='date-title'>Dated : 16 February, 2014</span>
            <p className='line-h'>
            Ethics Advanced Technology Limited (EATL) has signed an agreement with American International University – Bangladesh (AIUB) on 16th day of February, 2014 for collaboration in ICT, for its utilization and development. It was signed by Dr. Carmen Z. Lamagna, Vice Chancellor of American International University – Bangladesh (AIUB) and <strong>Mr. M. A. Mubin Khan</strong>, Chairman & MD of EATL.
            </p>

            <p className='line-h'>
            Chowdhury Imadul Islam, Project Manager from EATL and Director, Department of Science and Information Technology Mr. Mashiour Rahman, Assistant Professor and Head (Under Graduate Program) Dr. Dip Nandi, Assistant Professor and Head (Graduate Program)Dr. Tabin Hasan and Assistant Professor & Special Assistant, Department of Computer Science Mohammad Saiedur Rahaman were also present on this occasion. 
            </p>

            <p className='line-h'>
            This is an excellent collaboration between academia and ICT industry for engagement of students and teachers in training, mobile apps development, Research & Development activities. EATL and American International University – Bangladesh (AIUB) will work together for utilization of knowledge and skills in socio-economic development and supporting business sectors.
            </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={aiub} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default EatlSignedAiub;
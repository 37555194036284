import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import news1 from '../../../assets/images/news1.jpeg';
import IMG_3509 from '../../../assets/images/IMG_3509.jpeg';
import head_of_eatl from '../../../assets/images/head_of_eatl.jpg';


const Elearning = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>E-Learning fair at Jessore</h5>
            <span className='date-title'>Dated : 28 March, 2019</span>
            <p className='line-h'>
            A girl of class five student, expressed her feelings of astonishment, on watching the beautiful animations made on the topic of her books. She expressed that the science based animations were so beautiful that she cannot imagine all these hard core topic can be such nicely explained, presented in a an interesting video. One parents of jessore Government Girls’ High School said, “Now a days students are mostly engaged with mobile phone at home or free time. Such initiative of government on making beautiful animation video content lectures will certainly enhance their knowledge giving more option to learn though mobile and internet in their free time.” She really appreciated the idea.
            </p>
         </div>
         </Col>
         <Col md={6} data-aos="fade-left">
         <img className='news-details' src={news1} alt="com" />
         </Col>
      </Row>

      <Row className='border-topn'>
      <Col md={6} data-aos="fade-left">
         <img className='news-details' src={IMG_3509} alt="com" />
         </Col>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
           
            <p className='line-h'>
            This was mostly the feelings of overall participants – students, teachers, parents who participated in the e-Learning fair held on 23rd March 2019, Saturday from 10 am in the morning and continued till 3 pm afternoon in presence of thousands of students, teachers, parents, Government officials in the premises of Jessore Government Girls’ High School. Such kind of fairs are going to be arranged in this historic month of march by the Ethics Advanced Technology Ltd (EATL) for Secondary Education Sector Investment Program (SESIP) of Directorate of Secondary and Higher Education, NCTB, Ministry of Education and for all relevant stakeholders that have any relevant role in the digitization of education curriculum of Secondary and Higher Education.
            </p>
         </div>
         </Col>
       
      </Row>

      <Row className='border-topn column-reverse2'>
      <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
           
            <p className='line-h'>
            Bangladesh has achieved significant economic growth with impressive progress in human development indicators in the past decades. In order to build up an efficient system of more dynamic economy by 2021, it is imperative to reduce the poverty rate, where investing in the right type of education is the key to reduce poverty. Language, mathematics, history, science, information technology - technical knowledge and education, and a society with learning at its base are all matters of priority. SESIP has already initiated developing E-learning modules for six core subjects (Bangla, English, Mathematics, Science, Bangladesh Global Studies and ICT) to support ICT Learning Centers (ILCs) for “Improved Relevance and Quality of Curriculum”. The E-learning modules will be piloted in 640 schools. Ethics Advanced Technology Ltd (EATL), a leading ICT firm is the technology partner for developing these 150 contents with help of NCTB , SESIP and also playing the role of implementing the nationwide campaign for e-Learning awareness generation.
            </p>
         </div>
         </Col>
      <Col md={6} data-aos="fade-left">
         <img className='news-details' src={head_of_eatl} alt="com" />
         </Col>
  
      
      
       
      </Row>

      <Row className='border-topn'>
      <Col md={6} data-aos="fade-left">
         <img className='news-details' src={IMG_3509} alt="com" />
         </Col>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
           
            <p className='line-h'>
            The chief guest was, Abu Sayed Shiekh, Additional Secretary and JPD, SESIP who thanked all for participating the event, and he also shared SESIP activities, view point, objective to improve the secondary education system of the country. Md. Abdul Awal, DC jessore, who was present on the event as special guest and encouraged all students to enhance their knowledge in ICT. He emphasized that they are future leader and they need to give most importance to enhance their skill. The partner organization of the content development and the campaign project, the leading ICT firm EATL’s MD, Mr. M A Mubin Khan was also presente in the event. He shared his joy and surprise to see the excitement of students. He found the generation upcoming is very enthusiastic about technology and will lead themselves in the right way. He was present in other zonal eLearning fair such as in Mymensingh, comilla, Chittagong and he was surprised to explore the fact that school students today knows about machine learning, artificial intelligence and found it very promising for this country.

The 150 contents are available in YouTube channel and facebook page of eLearning BD. The facebook pages of eLearning BD has close to 50 thousand likes and readers are requested to like the page for updates and share the contents.
            </p>
         </div>
         </Col>
       
      </Row>
   </Container>
</Common>
);
};
export default Elearning;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Common from "../../layouts/Common";
import ResponsiveGallery from 'react-responsive-gallery';
import gal2 from '../../assets/images/2_1691310830.jpg';
import gal3 from '../../assets/images/3_1691310841.jpg';
import gal4 from '../../assets/images/nws2.jpg';
import gal5 from '../../assets/images/4_1691310849.jpg';
import gal6 from '../../assets/images/IMG_3509.jpeg';



import norts from "../../assets/images/norts.jpg";
import nua from "../../assets/images/nua.jpg";
import korms from "../../assets/images/korms.jpeg";
import bracku from "../../assets/images/bracku.jpg";
import mou from "../../assets/images/mou.jpg";
import diu from "../../assets/images/diu.jpg";
import minapp from "../../assets/images/minapp.jpg";
import dmana from "../../assets/images/dmana.jpg";
import signd from "../../assets/images/signd.jpg";
import family from "../../assets/images/family.jpg";
import devpro from "../../assets/images/devpro.jpg";


import anapp from "../../assets/images/anapp.jpg";
import uits from "../../assets/images/uits.jpg";



import '../contact/contact.css';

const Gallery = () => {
    
    return (
        <Common>
        <section className='sub-page-banner'>
           <Container>
              <Row>
                 <Col md={12} className='text-center sup-page-inner'>
                 <h2>Gallery</h2>
                 <Breadcrumb className='text-center custom-breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                    Gallery
                    </Breadcrumb.Item>
                 </Breadcrumb>
                 </Col>
              </Row>
           </Container>
        </section>
        <Container>
    
        <Row className='text-center mt-3'>
         <Col md={12}>
         <section className='work-process mt-5'>
            <h4 data-aos="flip-up">EATL Gallery</h4>
            
         </section>
         </Col>
      </Row>
      <Col md={12} className='mb-5'>
      <ResponsiveGallery
        useLightBox
        images={[
          {
            src: `${gal2}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1,
            orderL: 1,
            title: "Green Earth Quest Contest Launching Ceremony",
            
          },
          {
            src: `${gal3}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1, 
            orderL: 2,
            title: "Green Earth Quest Contest Launching Ceremony",
          },
          {
            src: `${gal5}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1,
            orderL: 3,
            title: "Green Earth Quest Contest Launching Ceremony",
          },
          {
            src: `${gal4}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1,
            orderL: 4,
            title: "আইসিটি বিভাগের উদ্যোগে ৫০০ মোবাইল এ্যাপস এর উদ্বোধন",
          },
         
          {
            src: `${gal6}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1,
            orderL: 5,
            title: "E-Learning fair at Jessore",
          },
          {
            src: `${norts}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1,
            orderL: 6,
            // imgClassName: 'img6-style',
            title: "Closing Ceremony of Android Apps Development Training Program at NSU",
          },

          {
            src: `${nua}`,
            alt: 'gallery',
            orderS: 6,
            orderM: 6,
            orderL: 6,
            // imgClassName: 'img6-style',
            title: "MOU signed with NSU",
          },
          {
            src: `${korms}`,
            alt: 'gallery',
            orderS: 6,
            orderM: 6,
            orderL: 6,
            // imgClassName: 'img6-style',
            title: "ডিজিটাল জন্মনিবন্ধন নিয়ে কর্মশালা",
          },
          {
            src: `${bracku}`,
            alt: 'gallery',
            orderS: 6,
            orderM: 6,
            orderL: 6,
            // imgClassName: 'img6-style',
            title: "EATL has signed an agreement with BRAC University.",
          },
          {
            src: `${mou}`,
            alt: 'gallery',
            orderS: 6,
            orderM: 6,
            orderL: 6,
            // imgClassName: 'img6-style',
            title: "MoU signing between EATL & University of Asia Pasific (UAP)",
          },






          {
            src: `${diu}`,
            alt: 'gallery',
            orderS: 7,
            orderM: 7,
            orderL: 7,
            // imgClassName: 'img6-style',
            title: "Android Apps Development Training Program at DIU",
          },
          {
            src: `${minapp}`,
            alt: 'gallery',
            orderS:8,
            orderM: 8,
            orderL: 8,
            // imgClassName: 'img6-style',
            title: "Workshop with Ministry of Education",
          },

          {
            src: `${dmana}`,
            alt: 'gallery',
            orderS:9,
            orderM: 9,
            orderL: 9,
            // imgClassName: 'img6-style',
            title: "Workshop with Food and Disaster Management",
          },
          {
            src: `${signd}`,
            alt: 'gallery',
            orderS:10,
            orderM: 10,
            orderL: 10,
            // imgClassName: 'img6-style',
            title: "MOU signed with DIU",
          },
          {
            src: `${family}`,
            alt: 'gallery',
            orderS:11,
            orderM: 11,
            orderL: 11,
            // imgClassName: 'img6-style',
            title: "Workshop with Ministry of Health and Family Welfare",
          },
          
          {
            src: `${devpro}`,
            alt: 'gallery',
            orderS:12,
            orderM: 12,
            orderL: 12,
            // imgClassName: 'img6-style',
            title: "MOU signing for Mobile Application Development Program",
          },


          {
            src: `${anapp}`,
            alt: 'gallery',
            orderS:13,
            orderM: 13,
            orderL: 13,
            // imgClassName: 'img6-style',
            title: "MOU signing for Mobile Application Development Program",
          },
          {
            src: `${uits}`,
            alt: 'gallery',
            orderS:14,
            orderM: 14,
            orderL: 14,
            // imgClassName: 'img6-style',
            title: "MOU signing for Mobile Application Development Program",
          },
          


        ]}
      />
      </Col>
      

        </Container>
     </Common>
            
          
      
    );
};

export default Gallery;
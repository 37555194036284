import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import { NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import pr1 from '../../../assets/images/pr1.jpg';
import pr2 from '../../../assets/images/pr2.png';
import pr3 from '../../../assets/images/pr3.jpg';
import pr4 from '../../../assets/images/pr4.png';
import pr5 from '../../../assets/images/file-tracking.png';
import pr6 from '../../../assets/images/hrms.png';
import pr7 from '../../../assets/images/pharma.png';
import pr8 from '../../../assets/images/education_mis.png';
import learningbig from '../../../assets/images/learningsmall.jpg';


const ProductsList = () => {
   return (
      <Common>
         <section className='sub-page-banner'>
            <Container>
               <Row>
                  <Col md={12} className='text-center sup-page-inner'>
                     <h2>Products </h2>
                     <Breadcrumb className='text-center custom-breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Products</Breadcrumb.Item>
                     </Breadcrumb>
                  </Col>
               </Row>
            </Container>
         </section>
         <Container className='mb-5 pt-5 mt-5 mtpt-custom'>

            <Row className='justify-content-md-center ms-0'>
           
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={pr2} alt="pr1" />
                     <h3>WebHIMS</h3>
                     <p> “WebHIMS” – Web based Hospital Information Management System is an ERP suite of software modules to computerize any large scale... </p>
                     <span>
                        <NavLink exact to="/webHIMS">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={pr3} alt="pr1" />
                     <h3>Edutube</h3>
                     <p>
                        Ethics Advanced Technology Limited (EATL) has launched Bangladesh First educational content sharing portal
                        <NavLink exact to="www.edutubebd.com"> dwww.edutubebd.com</NavLink>
                        on 11...
                     </p>
                     <span>
                        <NavLink exact to="/edutube">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={pr4} alt="pr1" />
                     <h3>Community Clinic Based MIS</h3>
                     <p> EATL developed database management system and dynamic website (Content management System-CMS)... </p>
                     <span>
                        <NavLink exact to="/clinic-based-mis">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={pr5} alt="pr5" />
                     <h3>FILE TRACKING SYSTEM
                     </h3>
                     <p> File Tracking System (FTS) is a web based application to monitor the pendency of receipts and files and assist in their easy trackin... </p>
                     <span>
                        <NavLink exact to="/file-tracking-system">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={pr6} alt="pr6" />
                     <h3>HRMS</h3>
                     <p>A Human Resource Management System (HRMS) or Human Resource Information System (HRIS), refers to the systems and processes ... </p>
                     <span>
                        <NavLink exact to="/hrms">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={pr7} alt="pr7" />
                     <h3>ERP PHARMA</h3>
                     <p>
                        Pharmaceutical ERP software is an enterprise resource planning solution especially designed and developed for pharmaceutical companies...
                     </p>
                     <span>
                        <NavLink exact to="/ERP-pharma">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={pr8} alt="pr8" />
                     <h3>WEB MIS FOR EDUCATION</h3>
                     <p> EATL has expertise on Education MIS development and management. One of the biggest projects of ETAL is the Education MIS developed... </p>
                     <span>
                        <NavLink exact to="/web-MIS">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={learningbig} alt="pr1" />
                     <h3>Online Learning Management System</h3>
                     <p> Our array of services... </p>
                     <span>
                        <NavLink exact to="">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={pr1} alt="pr1" />
                     <h3>Mobile Apps</h3>
                     <p> EATLapps is the first ever Bangladeshi Mobile Appstore. The company is established in 2012 under the umbrella of EATL ICT company... </p>
                     <span>
                        <NavLink exact to="/mobile-app">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
            </Row>
         </Container>
      </Common>
   );
};
export default ProductsList;
import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import inv1 from "../../../assets/images/inv1.jpg";
import inv2 from "../../../assets/images/inv2.jpg";
import inv3 from "../../../assets/images/inv3.jpg";
import inv4 from "../../../assets/images/inv4.jpg";
import inv5 from "../../../assets/images/inv5.jpg";





const InnovateMobileApps = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              UAP, EATL sign MoU to innovate mobile apps

              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 22 January, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${inv1}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "UAP, EATL sign MoU to innovate mobile apps",
              },
         
              
              {
                src: `${inv2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "UAP, EATL sign MoU to innovate mobile apps",
              },
              {
                src: `${inv3}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "UAP, EATL sign MoU to innovate mobile apps",
              },

              {
                src: `${inv4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                title:
                "UAP, EATL sign MoU to innovate mobile apps",
              },
              {
                src: `${inv5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                "UAP, EATL sign MoU to innovate mobile apps",
              },

        
              
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default InnovateMobileApps;

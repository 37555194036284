import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import m9 from "../../../assets/images/m9.jpg";
import m10 from "../../../assets/images/m10.jpg";
import m11 from "../../../assets/images/m11.jpg";
import m12 from "../../../assets/images/m12.jpg";
import m13 from "../../../assets/images/m13.jpg";
import m14 from "../../../assets/images/m14.jpg";
import m15 from "../../../assets/images/m15.jpg";


const MobileAppsBangla = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              Govt to make 500 mobile apps in Bangla
              </h4>
              <label className="text-gray mb-2 w-100">
                Dated : 02 September, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${m9}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "Govt to make 500 mobile apps in Bangla",
              },
              {
                src: `${m10}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                  "Govt to make 500 mobile apps in Bangla",
              },
              {
                src: `${m11}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "Govt to make 500 mobile apps in Bangla",
              },
              {
                src: `${m12}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "Govt to make 500 mobile apps in Bangla",
              },

              {
                src: `${m13}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                title:
                "Govt to make 500 mobile apps in Bangla",
              },
              {
                src: `${m14}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                "Govt to make 500 mobile apps in Bangla",
              },

              {
                src: `${m15}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 7,
                // imgClassName: 'img6-style',
                title:
                  "Govt to make 500 mobile apps in Bangla",
              },
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default MobileAppsBangla;

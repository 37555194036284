import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
import { NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';




const Vlog = () => {
return (
<Common>
   <section className='sub-page-banner'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Vlog </h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Vlog</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5 pt-5 mt-5 mtpt-custom'>

   <Row className='justify-content-md-center vlog-area ms-0'>
      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
     <iframe  src="https://www.youtube.com/embed/yXShOcKXG0k?si=jXNh-tU64LSeWu8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>এডুটিউব কুইজ প্রতিযোগিতা ২০২২ | Edutube Quiz Contest</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
     <iframe  src="https://www.youtube.com/embed/ix_lBrrYa18?si=P42NdETXAKMKxJLN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>এডুটিউব কুইজ প্রতিযোগিতা রাজশাহী বিভাগীয় রাউন্ডের প্রধান অতিথি BTRC'র চেয়ারম্যান শ্যাম সুন্দর সিকদার</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
     <iframe  src="https://www.youtube.com/embed/ix_lBrrYa18?si=P42NdETXAKMKxJLN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>এডুটিউব কুইজ প্রতিযোগিতা রাজশাহী বিভাগীয় রাউন্ডের প্রধান অতিথি BTRC'র চেয়ারম্যান শ্যাম সুন্দর সিকদার</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
     <iframe  src="https://www.youtube.com/embed/20dE5X82c90?si=9h4ZKLhMOGheE6-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>এডুটিউব কুইজ প্রতিযোগিতা ২০২২ উদ্বোধন নিয়ে বিশ্বব্যাংকের কান্ট্রি ডিরেক্টরের বক্তব্য</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
     <iframe  src="https://www.youtube.com/embed/arlIGzaN-XQ?si=4NoV9GzsiWPPfCdV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>Mr. Mustafa Jabbar, Honorable Minister, Ministry of Posts and Telecommunications | Green Earth Quest</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
    <iframe src="https://www.youtube.com/embed/ECSPfu63yEk?si=T1b2kOBQcc8ADgg_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>Launching Ceremony of Green Earth Quest Contest | গ্রীন আর্থ কোয়েস্ট প্রতিযোগিতার উদ্বোধনী অনুষ্ঠান</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
    <iframe  src="https://www.youtube.com/embed/rtp4X4tprhE?si=uuOMnAiQXcKCwOJg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3> A Mubin Khan, Hon'ble Managing Director, EATL | Green Earth Quest Contest | World Bank | EATL</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
   <iframe  src="https://www.youtube.com/embed/YcQBHv-yYWk?si=USumvFrW9crqA4xD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3> Dr. Kamal Abdul Naser Chowdhury, Former Principal Secretary of the Prime Minister's Office</h3>

      </div>
      </Col>

      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
   <iframe  src="https://www.youtube.com/embed/mSEq9Urz8eg?si=nD9nIgu0LDcofAbq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3> Prof. Dr. Muhammed Alamgir, Member, University Grants Commission | World Bank | EATL</h3>

      </div>
      </Col>


      <Col xs={12} md={6} lg={3}>
      <div className='left-block p-3 product' data-aos="fade-up"
      data-aos-duration="3000">
   <iframe src="https://www.youtube.com/embed/b3QH5biWPpE?si=awF2UgoK3rRgrrhq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>Dr. Rajesh Palit, Director (Technology), EATL | Green Earth Quest | World Bank | EATL</h3>

      </div>
      </Col>
            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
              <iframe  src="https://www.youtube.com/embed/CIxq1yYi64o?si=He3sKy0rAQ5lq3O9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>Launching Ceremony of EATL-Prothom Alo Apps Contest 2016</h3>
               
            </div>
            </Col>
            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
              <iframe src="https://www.youtube.com/embed/FNWZcFOFQf8?si=qex8G2U1mfvAPl6T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>EATL Prothom Alo Apps Contest 2015 Channel-i Talk Show Episode 04</h3>
               
            </div>
            </Col>
            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
             <iframe src="https://www.youtube.com/embed/Gq1nx_i-XI8?si=BPHEARu02p_WlLzb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>Encouraging Speech of Mr. Zunaid Ahmed Palak on EATL Protho Alo Apps </h3>
               
            </div>
            </Col>

          
              <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
              <iframe src="https://www.youtube.com/embed/b-pIqRxMBRs?si=oOopUnl-LeiSL158" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>EATL-Prothom Alo Apps Contest Journey-2015</h3>
               
            </div>
            </Col>

            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
             <iframe src="https://www.youtube.com/embed/iWMK0yVKBtE?si=cDkjCjZkDaM8FHoe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>EATL-Prothom Alo Apps Contest Grand Finale-2015 ATN</h3>
               
            </div>
            </Col>

            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
           <iframe src="https://www.youtube.com/embed/qs5sAVs7x64?si=vHG5cxTV1hh6O_jL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>EATL-Prothom Alo Apps Contest Grand Finale-2015 CH-9</h3>
               
            </div>
            </Col>
            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
           <iframe  src="https://www.youtube.com/embed/zS1ic2ze9ZM?si=l-s0l04Zu_qa6NGN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>EATL Prothom Alo Apps Contest 2015 Channel-i Talk Show Episode 02</h3>
               
            </div>
            </Col>

            <Col xs={12} md={6} lg={3}>
            <div className='left-block p-3 product' data-aos="fade-up"
               data-aos-duration="3000">
          <iframe  src="https://www.youtube.com/embed/6kKFmTnxueg?si=fmvU4hTxBIqP-hK7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               <h3>Top 350 Presentation session of EATL Prothom Alo apps Contest 2015 (Channel-i news coverage)</h3>
               
            </div>
            </Col>
         </Row>
   </Container>
</Common>
);
};
export default Vlog;
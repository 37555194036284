import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import mou from '../../../assets/images/mou.jpg';



const MouSigningEatlUap = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>MoU signing between EATL & University of Asia Pasific (UAP)</h5>
            <span className='date-title'>Dated : 16 February, 2014</span>
            <p className='line-h'>
            Ethics Advanced Technology Limited (EATL) has signed an agreement with University of Asia Pacific (UAP) today for collaboration in ICT, for its utilization and development. It was signed by Prof. Dr. Jamilur Reza Choudhury, Vice Chancellor of UAP and Mr. M A Mubin Khan, Chairman of EATL.
            </p>

            <p className='line-h'>
            Prof. Dr. M. R. Kabir, Pro Vice Chancellor of UAP, Dr. Nizam Uddin Ahmed, CEO of EATL, Dr. Md. Abdul Mazid, Registrar of UAP, Dr. Aloke Kumar Saha, Head of the Department, CSE of UAP and other academic professionals were also present on this occasion.  
            </p>

            <p className='line-h'>
            This is an excellent collaboration between academia and ICT industry for engagement of students and teachers in training, mobile apps development, Research & Development activities. EATL and UAP will work together for utilization of knowledge and skills in socio-economic development and supporting business sectors.            </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={mou} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default MouSigningEatlUap;
import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import ma12 from "../../../assets/images/ma12.jpg";
import ma13 from "../../../assets/images/ma13.jpg";
import ma14 from "../../../assets/images/ma14.jpg";
import ma15 from "../../../assets/images/ma15.jpg";
import ma16 from "../../../assets/images/ma16.jpg";
import ma17 from "../../../assets/images/ma17.jpg";

import ma18 from "../../../assets/images/ma18.jpg";
import ma19 from "../../../assets/images/ma19.jpg";
import ma20 from "../../../assets/images/ma20.jpg";
import ma21 from "../../../assets/images/ma21.jpg";



const Eatl26App = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 27 March, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${ma12}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
         
              
              {
                src: `${ma13}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
              {
                src: `${ma14}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },

              {
                src: `${ma15}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
              {
                src: `${ma16}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                // imgClassName: 'img6-style',
                title:
                "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },

              {
                src: `${ma17}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },

              {
                src: `${ma18}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 7,
                // imgClassName: 'img6-style',
                title:
                  "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
              {
                src: `${ma19}`,
                alt: "gallery",
                orderS:1,
                orderM: 1,
                orderL: 8,
                // imgClassName: 'img6-style',
                title:
                  "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
              {
                src: `${ma20}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 9,
                // imgClassName: 'img6-style',
                title:
                  "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
              {
                src: `${ma21}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 10,
                // imgClassName: 'img6-style',
                title:
                  "এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।’",
              },
              
             
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default Eatl26App;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import mapp1 from '../../../assets/images/mapp1.jpg';
import mapp2 from '../../../assets/images/mapp.png';
const MobileApp = () => {
return (
<Common>
   <section className='sub-page-banner'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Mobile Apps</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Products</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>Mobile Apps</h5>
            <p className='line-h'>
            EATLapps is the first ever Bangladeshi Mobile Appstore. The company is established in 2012 under the umbrella of EATL ICT company. EATL Apps store is branded as “EATL Apps” and its overall aim is to contribute in apps development and business sector with engagement of brightest talents & professionals both locally and internationally. It is contributing in developing economy, imaging the country, attracting young generations and supporting mobile users to utilize mobile apps' benefits effectively in practical life.
            </p>
            <Row>
            <Col md={6}>
            <img className='services-box' src={mapp1} alt="mapp1" />
            </Col>
            <Col md={6}>
            <img className='services-box' src={mapp2} alt="mapp2" />
            </Col>
           
         </Row>
            <p className='mt-3'>
            The major focus of EATLapps is to develop apps form the young talent of our country. Currently there are around 100 apps in the appstore comprising of 12-13 categories. “EATL Apps” hosts apps based on different mobile device configurations and safe payment procedure for users from both home and abroad. The apps are made to emphasize users' demand in all classes to attract more developers to come up with innovative ideas. Currently GP subscribers can download our free apps without data charge. And other paid apps can also be downloaded without paying any data charge for the downloads. DBBL credit card and Bkash online payment is also applicable for our apps purchase. Every year, our sister company - EATL organizes a nationwide apps development contest to search the brightest talent in apps development area. The top 10 apps from every year's contest can also be found in our store. Following documents may be helpful for you to get more idea about out contest
            </p>
         </div>
     
         </Col>
         <Col md={6} data-aos="fade-left">
         <div className='about-company mt-70'>
          
            <ul className='list-style-one mt-5'>
               <li> <strong>2013 Competition</strong></li>
               <li> <strong>2014 Competition</strong></li>
               <li> <strong>2015 Competition</strong></li>
        
            </ul>
            <p>
            EATLapps encourages everyone to upload his/her apps in the appstore. It’s is 100% free and simple. The revenue sharing model is currently as per BTRC guideline. If someone is interested in uploading any of his/her apps in EATLapps, he/she may mail at info@eatlapps.com. 
            </p>
         </div>
         </Col>
      
      </Row>
   </Container>
</Common>
);
};
export default MobileApp;
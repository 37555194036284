import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import m1 from "../../../assets/images/m1.jpg";
import m2 from "../../../assets/images/m2.jpg";
import m3 from "../../../assets/images/m3.jpg";
import m4 from "../../../assets/images/m4.jpg";
import m5 from "../../../assets/images/m5.jpg";
import m6 from "../../../assets/images/m6.jpg";
import m7 from "../../../assets/images/m7.jpg";
import m8 from "../../../assets/images/m8.jpg";

const ProthomAloApp = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
                ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫
              </h4>
              <label className="text-gray mb-2 w-100">
                Dated : 28 December, 2015
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${m1}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },
              {
                src: `${m2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },
              {
                src: `${m3}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },
              {
                src: `${m4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },

              {
                src: `${m5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },
              {
                src: `${m6}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },

              {
                src: `${m7}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },
              {
                src: `${m8}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 7,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫",
              },
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default ProthomAloApp;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import filetra from '../../../assets/images/filetra.png';
import filetr2 from '../../../assets/images/filetr2.png';
const FileTrackingSystem = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>File Tracking System</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>File Tracking System</h5>
                            <p className='line-h'>
                                File Tracking System (FTS) is a web based application to monitor the pendency of receipts and files and assist in their easy tracking.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <img className='services-box' src={filetra} alt="filetra" />
                                </Col>
                                <Col md={6}>
                                    <img className='services-box' src={filetr2} alt="filetr2" />
                                </Col>

                            </Row>
                            <p className='mt-3'>
                                The major focus of EATLapps is to develop apps form the young talent of our country. Currently there are around 100 apps in the appstore comprising of 12-13 categories. “EATL Apps” hosts apps based on different mobile device configurations and safe payment procedure for users from both home and abroad. The apps are made to emphasize users' demand in all classes to attract more developers to come up with innovative ideas. Currently GP subscribers can download our free apps without data charge. And other paid apps can also be downloaded without paying any data charge for the downloads. DBBL credit card and Bkash online payment is also applicable for our apps purchase. Every year, our sister company - EATL organizes a nationwide apps development contest to search the brightest talent in apps development area. The top 10 apps from every year's contest can also be found in our store. Following documents may be helpful for you to get more idea about out contest
                            </p>
                        </div>

                    </Col>
                    <Col md={6} data-aos="fade-left">
                        <div className='about-company mt-70'>

                            <ul className='list-style-one mt-5'>
                                <li> <strong>Completely web based</strong></li>
                                <li> <strong>An integrated package for diarizing of receipts/files</strong></li>
                                <li> <strong>Updating status</strong></li>
                                <li> <strong>Opening new files</strong></li>
                                <li> <strong>Tracking the movement of files</strong></li>
                                <li> <strong>Dispatching letters/files</strong></li>
                                <li> <strong>Managing complete records</strong></li>
                            </ul>
                            <p>
                                The software has been rolled out and implemented in several government departments.
                            </p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default FileTrackingSystem;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import cons1 from '../../../assets/images/cons1.png';
import con2 from '../../../assets/images/con2.png';
const ITConsultancy = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>IT Consultancy</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>IT Consultancy</h5>
                            <p className='line-h'>
                                EATL is one of the pioneers in providing IT infrastructure and solutions on various platforms, leading provider of information technology, consulting and business process services. We are one of the best IT solutions provider in managing, building and implementing IT environments of business systems critical to success in today's challenging and changing market.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left ">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={cons1} alt="cons1" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={con2} alt="con2" />
                            </Col>

                        </Row>
                    </Col>

                    <Col md={6} data-aos="fade-left">
                        <div className='about-company left-0 mt-4'>
                            <h3>
                                List of consultancy services:
                            </h3>
                            <ul className='list-style-one'>
                                <li>EATL does implementation and management of quality IT services</li>
                                <li>We are one of the best IT services company in industry.</li>
                                <li>We provide IT solution services.</li>
                                <li>We provide solution and maintenance for personal and commercial website, static and dynamic website.</li>
                                <li>We help to grow your business, achieve to target in your industry by step by step and strategic planning.</li>
                                <li>As we have IT skills and experienced professional IT team, we can make and follow to achieve tour targets business within time, budget and with quality standards.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default ITConsultancy;
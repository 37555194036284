import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import hiss from '../../../assets/images/hiss.png';
import hiss2 from '../../../assets/images/hiss2.png';
import himsbig from '../../../assets/images/himsbig.jpg';
const WebHIMS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>WebHIMS</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>WebHIMS</h5>
                            <p className='line-h'>
                                “WebHIMS” – Web based Hospital Information Management System is an ERP suite of software modules to computerize any large scale medical college, hospital and its branches. It is fully a web based software. The information is available anytime, anywhere. There are plenty of exhaustive modules available in WebHIMS V 1.0, which make it user friendly, highly versatile, quick installable and completely database driven.
                            </p>
                            <Row>
                                <Col md={6}>
                                    <img className='services-box' src={hiss} alt="hiss" />
                                </Col>
                                <Col md={6}>
                                    <img className='services-box' src={hiss2} alt="hiss2" />
                                </Col>

                            </Row>
                        </div>
                    </Col>

                    <Col md={6} data-aos="fade-left">
                        <div className='about-company mt-70'>
                            <h3 className='mt-3'>Key modules:</h3>
                            <ul className='list-style-one mt-2'>
                                <li>Patient Management</li>
                                <li>Clinical Management</li>
                                <li>Resource Management</li>
                                <li>Student Management</li>
                                <li>Finance Management</li>
                                <li>Human Resource (HR) Management</li>
                                <li>Store Management</li>
                                <li>Support Services Management</li>
                            </ul>

                        </div>
                    </Col>
                    <Col md={12}>
                        <div className='mt-5'>
                            <h3>Key Facilities:</h3>
                            <ul className='list-style-one mt-2'>
                                <li> WebHIMS is a comprehensive solution designed to automate all activities of a medical college or a hospital.</li>
                                <li>Designed with intuitive user interface, the system is capable of administering the total enterprise and manages information exchange efficiently.</li>
                                <li>WebHIMS aims at a fine balance between quality of care and efficiency of information turnaround.</li>
                                <li>From a healthcare industry focused company, it is a component based, process and workflow oriented, parameter driven enterprise management solution. WebHIMS has been created with the active participation of domain experts.</li>
                                <li>The development environment ensures that WebHIMS has the portability and connectivity to run on virtually all standard hardware platforms, with stringent data security and easy recovery in case of a system failure.</li>
                                <li>The system is designed modularly enabling users to plan implementation in different phases.</li>
                                <li>WebHIMS provides single patient viewer for patient medical records and financial details, and supports international standards in documentation for diseases, procedures, data exchanges, images and EDI.</li>
                                <li>WebHIMS provides the benefits of streamlined operations, enhanced administration and control, improved response to patient care, cost control, and improved profitability</li>
                                <li>The main features of WebHIMS include Modular Structure, Integrated Design, Multi Level Security, Easily Customizable, GUI Based and User Friendly.</li>
                                <li>WebHIMS provides automated alerts and reminders, online medication planning, drug interactions and outcome management, intensive search and analysis features through a powerful AI engine, structured viewer for examination and investigation of patient clinical information.</li>
                                <li>WebHIMS can help generate online queries and reports and has a robust security mechanism used for audit tracking every transaction. </li>
                                <li>Interfacing through HL7, while following ICD-10, SOAP and DICOM 3.0 Standards, with Open technological standards, WebHIMS has a framework toolkit which can be used to define clinical pathways for outcome management.</li>
                                <li>The integrated Application Software looks at the whole solution as an ERP-Implementation for the hospital.</li>
                                <li>The WebHIMS will change the way a medical college manages the collection of fees from the students.</li>
                                <li>The module based architecture has been a key user-friendly feature.</li>
                            </ul>
                            <p>
                               <strong> The software has been rolled out and implemented in several government departments.</strong>
                            </p>

                           
                        </div>

                        
                    </Col>
                    <Col md={6} className='offset-md-3 border-big-p mt-4'>
                    <img className='pro-big' src={himsbig} alt="hiss2" />
                        </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default WebHIMS;
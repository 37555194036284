import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import signd from '../../../assets/images/signd.jpg';



const MouSignedDiu = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>MOU signed with DIU</h5>
            <span className='date-title'>Dated : 30 November, 2013</span>
            <p className='line-h'>
           
            EATL and Daffodil International University signed an agreement on 30 th November 2013. As per this agreement both of the parties will work closely in three areas. Which are: research and development, Mobile Application Development and training. This MOU is one of the few example of Industry Academia Collaboration program in Bangladesh. Since very few companies in Bangladesh actually closely work with universities in collaboration for the development of student in the IT sector of Bangladesh. Daffodil International University VC Professor Lutfor rahman, PhD and head of CSE department Syed Akhter Hossain, PhD, EATL CEO Dr. Nizamudding Ahmed and Chairman M A Mubin Khan was present in the agreement signing ceremony. EATL and MCC are partners of a project named “National awareness and skill building program for mobile application development in Bangladesh.” run by the Ministry of ICT in Bangladesh. One of the major part of this project is to provide training on apps development all over Bangladesh which involves 64 districts. It would be both theoretical & hands on training in a phase by phase manner. 5 days training, daily 6 hours covering total 30 hours per district. As part of this program EATL will arrange the training session in Daffofill International University on 2nd Jan. And such training will be held in 64 districts as well. It may be mentioned that EATL has always worked closely with university students for apps development and as a part of EATL arranged Apps development contest this year EATL visited around 40 universities in Bangladesh which was indeed a different and rare initiative for any local company in Bangladesh. The free training arrangement on mobile apps development by MOICT initiative has created good response among students who registered in the portal.
            </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={signd} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default MouSignedDiu;
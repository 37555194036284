import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import callc from '../../../assets/images/callc.png';
import callc2 from '../../../assets/images/callc2.png';
const CallCenterSetup = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Call Center Setup & Implementation</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Call Center Setup & Implementation</h5>
                            <p className='line-h'>
                                Depending on customer requirements, EATL offers complete call center/telephony solution to the Private sectors, NGO, Banks and other service providers based on custom components. We have customers in NGO sector using our solution.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left ">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={callc} alt="callc" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={callc2} alt="callc2" />
                            </Col>

                        </Row>
                    </Col>

                    <Col md={6} data-aos="fade-left">
                        <div className='about-company left-0 mt-4'>
                            <h3>
                                Feature:
                            </h3>
                            <ul className='list-style-one'>
                                <li>Our call center is installed and configured by the highly trained professionals</li>
                                <li>We provide solutions that match your budget and requirements.</li>
                                <li>International experts for expert opinion</li>
                                <li>Customized billing software as per customer requirement </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default CallCenterSetup;